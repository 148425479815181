import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";
import Facebook from "./Facebook";
import Twitter from "./Twitter";
import useLocale from "../../contexts/LocaleWrapper";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({
  pageTitle,
  pageDescription,
  pageImage,
  pathname,
  page,
  template,
  noIndex,
}) => {
  // const { site } = useStaticQuery(query);
  // const {
  //   buildTime,
  //   siteMetadata: {
  //     siteTitle,
  //     siteDescription,
  //     siteUrl,
  //     logo,
  //     author,
  //     twitter,
  //     facebook,
  //   },
  // } = site;
  const { buildTime, site, settings } = useSiteMetadata();
  const {
    // siteTitle,
    // siteDescription,
    siteUrl,
    logo,
    author,
    twitter,
    facebook,
  } = site;
  // console.log(settings);
  // return null;
  //const localizedPath = i18n[locale].default ? '' : `/${i18n[locale].path}`
  const homeURL = `${siteUrl}`;

  const seo = {
    title: page
      ? pageTitle + " - " + settings.seo.metaTitle
      : settings.seo.metaTitle,
    description: page ? pageDescription : settings.seo.metaDescription,
    image: pageImage ? pageImage : settings.seo.metaImage.asset.url,
    url: `${siteUrl}${pathname || ""}`,
  };
  // console.log(seo);
  const { locale } = useLocale();

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: homeURL,
    //headline,
    //inLanguage: siteLanguage,
    mainEntityOfPage: homeURL,
    description: seo.description,
    name: seo.title,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    //datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${logo}`,
    },
  };

  let schemaArticle = null;

  if (page) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2019",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${logo}`,
        },
      },
      //datePublished: node.first_publication_date,
      //dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      //inLanguage: 'en',
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    };
  }
  // console.log(locale);

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={locale} />
        <meta name='description' content={seo.description} />
        <meta name='image' content={seo.image} />
        <meta
          name='google-site-verification'
          content='wd99ioo8WCMeivazm-S2GTivuceipGfkWm1yATH3yKg'
        />
        <link
          rel='apple-touch-icon-precomposed'
          href='/favicons/favicon-apple-touch-icon.png'
        />

        {/* <link rel='manifest' href='/favicons/site.webmanifest' /> */}
        <link
          rel='mask-icon'
          href='/favicons/favicon-safari-pinned-tab.svg'
          color='#1d1d1b'
        />
        <meta name='author' content='Kindernothilfe e.V.' />
        {/* <meta
          name='msapplication-config'
          content='favicons/browserconfig.xml'
        /> */}
        {/* <meta name='theme-color' content='#fff9cb' />
        <meta name='msapplication-TileColor' content='#fff9cb' /> */}
        {/* <meta
          name='msapplication-TileImage'
          content='/favicons/favicon-mstile-150x150.png'></meta> */}

        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
        {noIndex && (
          <meta name='robots' content='noindex' data-react-helmet='true' />
        )}
        {/* <meta name='gatsby-starter' content='Gatsby Starter Prismic i18n' /> */}
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!page && (
          <script type='application/ld+json'>
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {page && (
          <script type='application/ld+json'>
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {/* <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script> */}
        <body className={`${template} `} />
      </Helmet>
      {seo.title && seo.description && (
        <Facebook
          desc={seo.description}
          image={seo.image}
          title={seo.title}
          type={page ? "article" : "website"}
          url={seo.url}
          //locale={ogLanguage}
          name={facebook}
        />
      )}
      {seo.title && seo.description && (
        <Twitter
          title={seo.title}
          image={seo.image}
          desc={seo.description}
          username={twitter}
        />
      )}
    </>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  logo: PropTypes.string,
  pathname: PropTypes.string,
  page: PropTypes.bool,
  //node: PropTypes.object,
  locale: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  logo: null,
  pathname: null,
  page: false,
  //node: null,
  locale: "fr-fr",
};

// const query = graphql`
//   query SEO {
//     site {
//       buildTime(formatString: "YYYY-MM-DD")
//       siteMetadata {
//         siteTitle
//         siteDescription
//         siteUrl
//         logo
//         ogLanguage
//         author
//         twitter
//         facebook
//       }
//     }
//   }
// `;
