import React from "react";
import Seo from "../components/seo";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

const Home = ({ data }) => {
  const {
    settings: { seo },
  } = useSiteMetadata();
  // console.log(seo);
  return (
    <div className='home'>
      <Seo
        pageTitle={seo.metaTitle}
        pageDescription={seo.metaDescription}
        pageImage={seo.metaImage?.asset.url}
        template={`template-home `}
        page={false}
        noIndex={false}
      />
    </div>
  );
};

export default Home;
